import './App.css';
import Home from './pages/Home';
import Product from './pages/Product';
import Login from './pages/Login';
import CompanyAndServices from './pages/CompanyAndServices';
import ContactUs from './pages/ContactUs';
import General from './pages/General';
import Config from './pages/Config';
import Demo from './pages/Demo';
import { SnackProvider } from 'components/dumb/MuiSnackBar';
import AuthProvider from 'context/AuthProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

const theme = createTheme({
  palette: {
    primary: {
      main: '#889dbc',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E33E7F',
    },
  },
});

const PageRoutes = () =>
  useRoutes([
    { path: '/home', element: <Home /> },
    { path: '/demo', element: <Demo /> },
    { path: '/login', element: <Login /> },
    { path: '/', element: <Login /> },
    { path: '/product', element: <Product /> },
    { path: '/company', element: <CompanyAndServices /> },
    { path: '/contactus', element: <ContactUs /> },
    { path: '/general', element: <General /> },
    { path: '/config', element: <Config /> },
    { path: '*', element: <h1>404 Page not found</h1> },
  ]);

function App() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <SnackProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <BrowserRouter>
              <PageRoutes />
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>
      </SnackProvider>
    </ThemeProvider>
  );
}

export default App;
