import { Stack } from '@mui/material';
import InputMultLang from 'components/smart/InputMultLang';
import PageSection from 'components/smart/PageSection';
import SectionMeta from 'components/smart/SectionMeta';

const SectionProductCompare = () => {
  return (
    <>
      <SectionMeta title="產品比較" fieldPath="pageInfo" />
      <PageSection sectionTitle="產品比較欄文字">
        <Stack p="10px" spacing={3}>
          <InputMultLang label="備註" fieldPath="labels.remarks" />
          <InputMultLang label="分享文字" fieldPath="labels.share" />
          <InputMultLang label="更多相片" fieldPath="labels.Pics" />
          <InputMultLang label="價錢" fieldPath="labels.price_show" />
          <InputMultLang label="公司編碼" fieldPath="labels._id" />
          <InputMultLang label="品牌" fieldPath="labels.brand_id" />
          <InputMultLang label="有關資料" fieldPath="labels.detail_desc" />
          <InputMultLang label="手錶尺碼" fieldPath="labels.size" />
          <InputMultLang label="手錶功能" fieldPath="labels.features" />
          <InputMultLang label="機芯" fieldPath="labels.mech" />
          <InputMultLang label="錶殼材料" fieldPath="labels.body" />
          <InputMultLang label="面盤" fieldPath="labels.dial" />
          <InputMultLang label="刻度字款" fieldPath="labels.markers" />
          <InputMultLang label="錶帶" fieldPath="labels.bracelet" />
          {/* mobile */}
          <InputMultLang
            label="比較數量(手機)"
            fieldPath="labels.mobileCompareCount"
          />
          <InputMultLang
            label="比較第一頁(手機)"
            fieldPath="labels.mobilePageOne"
          />
          <InputMultLang
            label="比較第一頁(手機)"
            fieldPath="labels.mobilePageTwo"
          />
        </Stack>
      </PageSection>
    </>
  );
};
export default SectionProductCompare;
