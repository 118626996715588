import PageSection from 'components/smart/PageSection';
import MultRte from 'components/smart/MultRte';
import { Stack } from '@mui/material';

interface ISectionTextPage {
  title: string;
  fieldPath: string;
}

const SectionServices = (props: ISectionTextPage) => {
  const { title, fieldPath } = props;
  return (
    <PageSection sectionTitle={title}>
      <Stack p="10px" spacing={3}>
        <MultRte label={title} fieldPath={fieldPath} />
      </Stack>
    </PageSection>
  );
};

export default SectionServices;
