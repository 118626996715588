import {
  IContact,
  IHome,
  ITextPage,
  IHeader,
  IFooter,
  IProductCategory,
  IProductCompare,
  IProductDetails,
  IProductShare,
  IConfig,
} from 'common/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getPage, updatePage } from 'services/api';

export const useContactQuery = () =>
  useQuery<IContact, Error>('contact', async () => getPage('contact'));

export const useCompanyQuery = () =>
  useQuery<ITextPage, Error>('company', async () => getPage('company'));

export const useServicesQuery = () =>
  useQuery<ITextPage, Error>('services', async () => getPage('services'));

export const useFaqQuery = () =>
  useQuery<ITextPage, Error>('faq', async () => getPage('faq'));

export const usePaymentQuery = () =>
  useQuery<ITextPage, Error>('payment', async () => getPage('payment'));

export const useTncQuery = () =>
  useQuery<ITextPage, Error>('tnc', async () => getPage('tnc'));

export const useHeaderQuery = () =>
  useQuery<IHeader, Error>('header', async () => getPage('header'));

export const useFooterQuery = () =>
  useQuery<IFooter, Error>('footer', async () => getPage('footer'));

export const useHomeQuery = () =>
  useQuery<IHome, Error>('home', async () => getPage('home'));

export const useProductCategoryQuery = () =>
  useQuery<IProductCategory, Error>('productCategory', async () =>
    getPage('productCategory')
  );

export const useProductDetailsQuery = () =>
  useQuery<IProductDetails, Error>('productDetails', async () =>
    getPage('productDetails')
  );

export const useProductCompareQuery = () =>
  useQuery<IProductCompare, Error>('productCompare', async () =>
    getPage('productCompare')
  );

export const useProductShareQuery = () =>
  useQuery<IProductShare, Error>('productShare', async () =>
    getPage('productShare')
  );

export const useConfigQuery = () =>
  useQuery<IConfig, Error>('config', async () => getPage('config'));

export const usePageMutation = (pageId: string) => {
  const qc = useQueryClient();
  return useMutation(
    (values: any) => {
      return updatePage(pageId, values);
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(pageId);
      },
    }
  );
};
