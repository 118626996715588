import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import MultRte from 'components/smart/MultRte';
import InputFile from 'components/smart/InputFile';

const SectionLocation = () => {
  return (
    <>
      <PageSection sectionTitle="營業時間 &#38; 本店位置">
        <Stack p="10px" spacing={3}>
          <MultRte label="營業時間 &#38; 本店位置" fieldPath="locationHtml" />
          {/* <InputFile bucket="static" hasLink label="地圖" fieldPath="mapInfo" /> */}
        </Stack>
      </PageSection>
    </>
  );
};

export default SectionLocation;
