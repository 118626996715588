import { Stack, Button, TextField, Fab } from '@mui/material';
import { FileItem } from 'common/types';
import useFormikArray from 'hooks/useFormikArray';
import useMinioUpload from 'hooks/useMinioUpload';
import { FastField, FieldProps } from 'formik';
import { makeid } from 'common/utils';

interface IInputFiles {
  label: string;
  fieldPath: string;
  bucket: string;
  hasLink?: boolean;
  hasDesc?: boolean;
}

const InputFile = (props: IInputFiles) => {
  const { label, hasLink, hasDesc, fieldPath, bucket } = props;
  const { arrayFieldValue, addItem, removeItem, moveUpItem, moveDownItem } =
    useFormikArray(fieldPath);
  const { isUploading, upload } = useMinioUpload();

  const sampleFile = {
    _id: makeid(6),
    src: '',
    href: '',
    desc: '',
  } as FileItem;

  return (
    <>
      <Stack direction="row" alignItems="flex-start">
        <Stack flex="0 0 200px">
          <h3>{label}</h3>
        </Stack>
        <Stack flex="1 0 450px" direction="column">
          <Stack direction="column" spacing={3}>
            {arrayFieldValue &&
              arrayFieldValue.map((f: FileItem, i: number) => {
                return (
                  <Stack
                    direction="row"
                    key={f._id + '_' + i}
                    p="20px"
                    border="1px solid #888"
                    borderRadius="10px"
                  >
                    <Stack flex="1 0 100px" direction="column" spacing={2}>
                      <Stack direction="row" spacing={1}>
                        <FastField name={`${fieldPath}[${i}].src`}>
                          {(props: FieldProps) => (
                            <>
                              <Button
                                variant="contained"
                                component="label"
                                disabled={isUploading}
                              >
                                上傳圖片
                                <input
                                  type="file"
                                  hidden
                                  onChange={async (e) => {
                                    const res = await upload(
                                      bucket,
                                      e.target.files
                                    );
                                    if (res)
                                      props.form.setFieldValue(
                                        `${fieldPath}[${i}].src`,
                                        res
                                      );
                                  }}
                                />
                              </Button>

                              <TextField
                                sx={{ flex: '1 0 200px' }}
                                size="small"
                                label="或直接貼上圖片地址"
                                autoComplete="off"
                                variant="outlined"
                                {...props.field}
                              />
                            </>
                          )}
                        </FastField>
                      </Stack>
                      {hasLink && (
                        <Stack>
                          <FastField name={`${fieldPath}[${i}].href`}>
                            {(props: FieldProps) => (
                              <TextField
                                fullWidth
                                size="small"
                                label="URL"
                                autoComplete="off"
                                variant="outlined"
                                {...props.field}
                              />
                            )}
                          </FastField>
                        </Stack>
                      )}
                      {hasDesc && (
                        <Stack>
                          <FastField name={`${fieldPath}[${i}].desc`}>
                            {(props: FieldProps) => (
                              <TextField
                                fullWidth
                                size="small"
                                label="圖片描述"
                                autoComplete="off"
                                variant="outlined"
                                {...props.field}
                              />
                            )}
                          </FastField>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      flex="0 0 80px"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Fab
                        color="default"
                        size="small"
                        sx={{ fontSize: '20px' }}
                        onClick={() => removeItem(i)}
                      >
                        &#10006;
                      </Fab>
                      {/* move up */}
                      {i !== 0 && (
                        <Fab
                          color="primary"
                          size="small"
                          sx={{ fontSize: '15px' }}
                          onClick={() => moveUpItem(i)}
                        >
                          &#9650;
                        </Fab>
                      )}

                      {/* move down */}
                      {i < arrayFieldValue.length - 1 && (
                        <Fab
                          color="primary"
                          size="small"
                          sx={{ fontSize: '15px' }}
                          onClick={() => moveDownItem(i)}
                        >
                          &#9660;
                        </Fab>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>

          <Stack>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              sx={{ margin: '10px 0 15px 0', fontSize: '20px' }}
              onClick={() => addItem(sampleFile)}
            >
              +
            </Fab>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default InputFile;
