import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputTxtArea from 'components/smart/InputTxtArea';

const SectionGTM = () => {
  return (
    <PageSection sectionTitle="Google Tag Manager">
      <Stack p="10px" spacing={3}>
        <InputTxtArea label="GTM Script (Head)" fieldPath="codeGTM.head" />
        <InputTxtArea label="GTM Script (Body)" fieldPath="codeGTM.body" />
      </Stack>
    </PageSection>
  );
};

export default SectionGTM;
