import PageSection from 'components/smart/PageSection';
import InputMultLang from 'components/smart/InputMultLang';
import InputText from 'components/smart/InputText';
import { Stack } from '@mui/material';
import Breadcrumb from 'components/smart/Breadcrumb';

interface ISectionMeta {
  title: string;
  fieldPath: string;
}

const SectionMeta = (props: ISectionMeta) => {
  const { title, fieldPath } = props;
  return (
    <>
      <PageSection sectionTitle={title + ' 網頁資訊'}>
        <Stack p="10px" spacing={3}>
          <InputMultLang
            label={'網頁標題'}
            fieldPath={`${fieldPath}.pageTitle`}
          />
          <InputText label={'網頁描述'} fieldPath={`${fieldPath}.pageDesc`} />
          <Breadcrumb
            label={'網頁路徑'}
            fieldPath={`${fieldPath}.breadcrumb`}
          />
        </Stack>
      </PageSection>
    </>
  );
};

export default SectionMeta;
