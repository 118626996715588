import { IFormAccount, IFormPassword } from 'common/types';
import { useCallback } from 'react';

export const useValidation = () => {
  const validateAccountForm = useCallback(
    (isRegister: boolean, account: IFormAccount) => {
      const errors = {} as IFormAccount;
      if (!account.name) {
        errors.name = '請輸入用戶名';
      } else if (account.name.length < 3) {
        errors.name = '用戶名最少3個英文字母';
      } else if (!/^[a-zA-Z0-9]+$/.test(account.name)) {
        errors.name = '用戶名只能英文字母和數字';
      }

      if (!account.pass) {
        errors.pass = '請輸入密碼';
      } else if (account.pass.length < 3) {
        errors.pass = '密碼長度最少3字元';
      }

      if (isRegister) {
        if (!account.pass2) {
          errors.pass2 = '請輸入確認密碼';
        } else if (account.pass !== account.pass2) {
          errors.pass2 = '密碼不一致';
        }
      }

      return errors;
    },
    []
  );

  const validatePassWordForm = useCallback((passwordForm: IFormPassword) => {
    const errors = {} as IFormPassword;
    if (!passwordForm.oldPass) {
      errors.oldPass = '請輸入舊密碼';
    } else if (passwordForm.oldPass.length < 3) {
      errors.oldPass = '密碼最少3個英文字母';
    }

    if (!passwordForm.newPass) {
      errors.newPass = '請輸入新密碼';
    } else if (passwordForm.newPass.length < 3) {
      errors.newPass = '密碼長度最少3字元';
    }

    if (!passwordForm.newPass2) {
      errors.newPass2 = '請輸入確認新密碼';
    } else if (passwordForm.newPass !== passwordForm.newPass2) {
      errors.newPass2 = '新密碼不一致';
    }

    return errors;
  }, []);

  return { validateAccountForm, validatePassWordForm };
};

export default useValidation;
