import PageSection from 'components/smart/PageSection';
import MultRte from 'components/smart/MultRte';
import { Stack } from '@mui/material';
import InputMultLang from 'components/smart/InputMultLang';
import InputMultLangs from 'components/smart/InputMultLangs';

const SectionContact = () => {
  return (
    <>
      <PageSection sectionTitle="聯絡資訊">
        <Stack p="10px" spacing={3}>
          <MultRte label="聯絡我們" fieldPath="contactHtml" />
        </Stack>
        <Stack p="10px" spacing={3}>
          <InputMultLang label="表格名字" fieldPath="contactForm.name" />
          <InputMultLang label="表格電話" fieldPath="contactForm.phone" />
          <InputMultLang label="表格電郵" fieldPath="contactForm.email" />
          <InputMultLang
            label="表格查詢事項"
            fieldPath="contactForm.enquiryText"
          />
          <InputMultLangs
            label="表格查詢事項"
            fieldPath="contactForm.enquiryOptions"
          />
          <InputMultLang label="表格查詢訊息" fieldPath="contactForm.message" />
          <InputMultLang
            label="表格清除按鈕"
            fieldPath="contactForm.btnClear"
          />
          <InputMultLang label="表格傳送按鈕" fieldPath="contactForm.btnSend" />
        </Stack>
      </PageSection>
    </>
  );
};

export default SectionContact;
