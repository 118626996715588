import { Stack } from '@mui/material';
import InputMultLang from 'components/smart/InputMultLang';
import PageSection from 'components/smart/PageSection';
import SectionMeta from 'components/smart/SectionMeta';
import InputFiles from 'components/smart/InputFiles';

const SectionProductDetails = () => {
  return (
    <>
      <SectionMeta title="產品詳情" fieldPath="pageInfo" />
      <PageSection sectionTitle="產品詳情設定">
        <Stack p="10px" spacing={3}>
          {/* left image */}
          <InputMultLang label="加入比較" fieldPath="addToCompareText" />
          <InputMultLang label="按此放大" fieldPath="clickToEnlarge" />

          {/* right text */}
          <InputMultLang label="公司編號" fieldPath="companyCodeText" />
          <InputMultLang label="型號" fieldPath="modelNumText" />
          <InputMultLang label="手錶尺碼" fieldPath="watchSizeText" />
          <InputMultLang label="錶殼尺寸" fieldPath="diameterText" />
          <InputMultLang label="面盤顏色" fieldPath="watchFaceColor" />
          <InputMultLang label="價格" fieldPath="priceText" />
          <InputMultLang label="詳情資料" fieldPath="detailsInfoText" />
          <InputMultLang label="分享給朋友" fieldPath="shareSocialMediaText" />
          <InputMultLang label="銷售地點" fieldPath="locationText" />

          {/* social media section */}
          <InputFiles
            bucket="static"
            hasLink
            label="社交媒體"
            fieldPath="shareSocialMedia"
          />
          <InputMultLang
            label="相關或其他產品"
            fieldPath="relatedProductText"
          />
          <InputMultLang
            label="已複製"
            fieldPath="copied"
          />
        </Stack>
      </PageSection>
    </>
  );
};
export default SectionProductDetails;
