import { TextField, Stack } from '@mui/material';
import { FastField, FieldProps } from 'formik';

interface IInputMultLang {
  label: string;
  fieldPath: string;
  size?: 'small' | 'medium';
  labelWidth?: string;
}

export const InputMultLang = (props: IInputMultLang) => {
  const { label, fieldPath, size, labelWidth } = props;
  const finalLabelWidth = labelWidth ? `0 0 ${labelWidth}` : '0 0 200px';

  return (
    <Stack direction="row">
      <Stack flex={finalLabelWidth}>
        <h3>{label}</h3>
      </Stack>
      <Stack direction="row" spacing={2} flex="1 0 300px">
        <Stack flex="1 0 100px">
          <FastField name={`${fieldPath}.tc`}>
            {(props: FieldProps) => (
              <TextField
                size={size}
                fullWidth
                autoComplete="off"
                label="繁"
                variant="outlined"
                {...props.field}
              />
            )}
          </FastField>
        </Stack>
        <Stack flex="1 0 100px">
          <FastField name={`${fieldPath}.sc`}>
            {(props: FieldProps) => (
              <TextField
                size={size}
                fullWidth
                autoComplete="off"
                label="簡"
                variant="outlined"
                {...props.field}
              />
            )}
          </FastField>
        </Stack>
        <Stack flex="1 0 100px">
          <FastField name={`${fieldPath}.en`}>
            {(props: FieldProps) => (
              <TextField
                size={size}
                fullWidth
                autoComplete="off"
                label="英"
                variant="outlined"
                {...props.field}
              />
            )}
          </FastField>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InputMultLang;
