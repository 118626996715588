import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputFile from 'components/smart/InputFile';
import InputMultLang from 'components/smart/InputMultLang';

const Section404 = () => {
  return (
    <PageSection sectionTitle="頁面404">
      <Stack p="10px" spacing={3}>
        <InputFile label="圖片" fieldPath="page404.img" bucket="static" />
        <InputMultLang label="標題" fieldPath="page404.title" />
        <InputMultLang label="描述" fieldPath="page404.desc" />
        <InputMultLang label="描述2" fieldPath="page404.desc2" />
        <InputMultLang label="按鈕" fieldPath="page404.butTxt" />
      </Stack>
    </PageSection>
  );
};

export default Section404;
