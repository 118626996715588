import { useState, useEffect } from 'react';
import { Avatar, Divider, Stack, Button, Menu, MenuItem } from '@mui/material';
import FormResetPassword from 'components/smart/FormResetPassword';
import { Link } from 'react-router-dom';
import { pages } from 'common/utils';
import useAuth from 'hooks/useAuth';

const Header = () => {
  const { user, authLogout, authPing } = useAuth();
  const [openResetForm, setOpenResetForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const locPath = window.location.pathname;
  useEffect(() => {
    if (!user) {
      authPing(); //this is require to setup the user
    }
  }, [user]);

  return (
    <>
      <FormResetPassword
        open={openResetForm}
        handleClose={() => setOpenResetForm(false)}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        p="20px"
        sx={{ backgroundColor: '#DE5A41' }}
      >
        <Stack direction="row" alignItems="baseline">
          <Stack sx={{ color: '#ECECEC', fontSize: '36px', fontWeight: '700' }}>
            KKWatch CMS
          </Stack>
          <Stack mx="10px" my="5px" spacing={2} direction="row">
            {pages.map((page) => {
              return (
                <Link
                  key={page.path}
                  to={page.path}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    sx={{
                      fontSize: '16px',
                      fontWeight: 600,
                    }}
                    style={{
                      color: locPath === page.path ? '#fff' : '#333',
                    }}
                  >
                    {page.text}
                  </Button>
                </Link>
              );
            })}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Avatar
            src=""
            sx={{ background: '#87a8b9', boxShadow: 3, marginRight: '30px' }}
          >
            {user?.name.slice(0, 2)}
          </Avatar>
        </Stack>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem disabled>{user?.name}</MenuItem>
          <MenuItem onClick={() => setOpenResetForm(true)}>重設密碼</MenuItem>
          <MenuItem onClick={authLogout}>登出</MenuItem>
        </Menu>
      </Stack>
      <Divider />
      {/* // menu */}

      <Divider />
    </>
  );
};

export default Header;
