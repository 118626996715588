import { Card } from '@mui/material';
interface IPageSection {
  sectionTitle: string;
}
const PageSection = (props: React.PropsWithChildren<IPageSection>) => {
  const title = props.sectionTitle;

  return (
    <section style={{ padding: '20px 50px' }}>
      <h2 style={{ textAlign: 'center' }}>{title}</h2>
      <Card
        sx={{ padding: '42px 24px', borderRadius: '20px', minWidth: '720px' }}
      >
        {props.children}
      </Card>
    </section>
  );
};

export default PageSection;
