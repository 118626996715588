import { Stack } from '@mui/material';
import PageSection from 'components/smart/PageSection';
import InputMultLang from 'components/smart/InputMultLang';

const SectionProductShared = () => {
  return (
    <PageSection sectionTitle="產品圖片標示">
      <Stack p="10px" spacing={3}>
        <InputMultLang label="現貨" fieldPath="IcoInStock" />
        <InputMultLang label="缺貨" fieldPath="IcoOutofStock" />
        <InputMultLang label="比較太多產品" fieldPath="CompareExceed" />
      </Stack>
    </PageSection>
  );
};
export default SectionProductShared;
