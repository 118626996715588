const APP_API_URL = process.env.REACT_APP_API || '';

const pathsSkip401 = ['/login', '/pingAuth'];

export const makeid = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const pages = [
  {
    path: '/general',
    text: '一般',
  },
  {
    path: '/home',
    text: '首頁',
  },
  {
    path: '/company',
    text: '公司和服務',
  },
  {
    path: '/product',
    text: '產品',
  },
  {
    path: '/contactus',
    text: '聯絡',
  },
  {
    path: '/config',
    text: '配置',
  },
];

export const request = (
  url: string,
  options?: RequestInit,
  files?: FileList
) => {
  const fullUrl = url.includes('://') ? url : APP_API_URL + url;
  const onSuccess = (response: Response) => {
    if (response.ok) {
      if (response.status === 200) {
        return response.json();
      }
    } else {
      if (response.status === 400) {
        return response.json();
      } else if (response.status === 401) {
        if (!pathsSkip401.includes(url)) {
          window.location.href = '/';
        }
      }

      return response.text().then((text) => {
        throw new Error(text);
      });
    }
  };
  const onError = (error: Error) => {
    console.error(error);
    // return error;
  };

  //option required
  if (!options) options = {} as RequestInit;
  options.credentials = 'include';
  options.headers = files
    ? {
        accept: '*/*',
        ...options.headers,
      }
    : {
        'content-type': 'application/json',
        ...options.headers,
      };

  if (files) {
    const selectedFile = files[0];
    const formData = new FormData();
    formData.append('images', selectedFile);
    options.method = 'POST';
    options.body = formData;
  }

  return fetch(fullUrl, options).then(onSuccess).catch(onError);
};
