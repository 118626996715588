import Header from 'components/smart/Header';
import { useConfigQuery, usePageMutation } from 'hooks/useAppQuery';
import { Formik, Form } from 'formik';
import SavePageButton from 'components/smart/SavePageButton';
import { useEffect } from 'react';
import { useSnackBar } from 'components/dumb/MuiSnackBar';
import SectionGTM from './SectionGTM';
import Section404 from './Section404';
import SectionIcon from './SectionIcon';
import SectionContact from './SectionContact';
import SectionMailServer from './SectionMailServer';

const Config = () => {
  const { showSnackBar } = useSnackBar();
  const { isError, isLoading, data } = useConfigQuery();
  const {
    isLoading: isLoadingMutation,
    mutate,
    isSuccess: isSuccessMutation,
    isError: isErrorMutation,
  } = usePageMutation('config');

  useEffect(() => {
    if (isSuccessMutation) {
      showSnackBar('保存成功', 'success');
    }
    if (isErrorMutation) {
      showSnackBar('保存失敗', 'error');
    }
  }, [isSuccessMutation, isErrorMutation]);

  if (isLoading) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  if (isError) {
    return <p>Error found</p>;
  }

  return (
    <>
      <Header />
      {data && (
        <Formik
          validateOnChange={false}
          initialValues={data}
          onSubmit={(values, { setSubmitting }) => {
            mutate(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionGTM />
            <SectionIcon />
            <Section404 />
            <SectionContact />
            <SectionMailServer />
            <SavePageButton isLoading={isLoadingMutation} />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default Config;
