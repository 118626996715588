import { useEffect, useRef } from 'react';
import { Form, Formik, FormikValues, FormikProps } from 'formik';
import {
  useProductCompareQuery,
  useProductCategoryQuery,
  useProductDetailsQuery,
  useProductShareQuery,
  usePageMutation,
} from 'hooks/useAppQuery';
import { useSnackBar } from 'components/dumb/MuiSnackBar';
import Header from 'components/smart/Header';
import SectionProductCategory from './SectionProductCategory';
import SectionProductDetails from './SectionProductDetails';
import SectionProductCompare from './SectionProductCompare';
import SectionProductShared from './SectionProductShared';
import SavePageButton from 'components/smart/SavePageButton';

const Product = () => {
  const { showSnackBar } = useSnackBar();

  const refFormCom = useRef<FormikProps<FormikValues>>(null);
  const refFormCat = useRef<FormikProps<FormikValues>>(null);
  const refFormDtl = useRef<FormikProps<FormikValues>>(null);
  const refFormShr = useRef<FormikProps<FormikValues>>(null);

  const { data: dataShr } = useProductShareQuery();
  const {
    isError: isErrCat,
    isLoading: isLoadingCat,
    data: dataCat,
  } = useProductCategoryQuery();

  const {
    isError: isErrCom,
    isLoading: isLoadingCom,
    data: dataCom,
  } = useProductCompareQuery();
  const {
    isError: isErrDtl,
    isLoading: isLoadingDtl,
    data: dataDtl,
  } = useProductDetailsQuery();

  const {
    isLoading: isLoadingMttShr,
    mutate: mutateShr,
    isSuccess: isMttSuccessShr,
    isError: isMttErrorShr,
  } = usePageMutation('productShare');
  const {
    isLoading: isLoadingMttCat,
    mutate: mutateCat,
    isSuccess: isMttSuccessCat,
    isError: isMttErrorCat,
  } = usePageMutation('productCategory');
  const {
    isLoading: isLoadingMttCom,
    mutate: mutateCom,
    isSuccess: isMttSuccessCom,
    isError: isMttErrorCom,
  } = usePageMutation('productCompare');
  const {
    isLoading: isLoadingMttDtl,
    mutate: mutateDtl,
    isSuccess: isMttSuccessDtl,
    isError: isMttErrorDtl,
  } = usePageMutation('productDetails');

  useEffect(() => {
    if (
      isMttSuccessShr &&
      isMttSuccessDtl &&
      isMttSuccessCom &&
      isMttSuccessCat
    )
      showSnackBar('保存成功', 'success');
    if (isMttErrorCat || isMttErrorShr || isMttErrorCom || isMttErrorDtl)
      showSnackBar('保存失敗', 'error');
  }, [
    isMttSuccessShr,
    isMttSuccessDtl,
    isMttSuccessCom,
    isMttSuccessCat,
    isMttErrorCat,
    isMttErrorShr,
    isMttErrorCom,
    isMttErrorDtl,
  ]);

  if (isLoadingCat || isLoadingCom || isLoadingDtl) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  if (isErrCat || isErrCom || isErrDtl) {
    return <p>Error found</p>;
  }

  return (
    <>
      <Header />
      {(dataShr || dataCat) && (
        <Formik
          validateOnChange={false}
          initialValues={
            dataShr || {
              IcoInStock: dataCat?.IcoInStock,
              IcoOutofStock: dataCat?.IcoOutofStock,
              CompareExceed: dataCat?.CompareExceed,
            }
          }
          innerRef={refFormShr}
          onSubmit={(values, { setSubmitting }) => {
            mutateShr(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionProductShared />
          </Form>
        </Formik>
      )}

      {dataCat && (
        <Formik
          validateOnChange={false}
          initialValues={dataCat}
          innerRef={refFormCat}
          onSubmit={(values, { setSubmitting }) => {
            values = {
              ...refFormShr.current?.values,
              ...values,
            };
            mutateCat(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionProductCategory />
          </Form>
        </Formik>
      )}

      {dataDtl && (
        <Formik
          validateOnChange={false}
          initialValues={dataDtl}
          innerRef={refFormDtl}
          onSubmit={(values, { setSubmitting }) => {
            values = {
              ...refFormShr.current?.values,
              ...values,
            };
            mutateDtl(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionProductDetails />
          </Form>
        </Formik>
      )}

      {dataCom && (
        <Formik
          validateOnChange={false}
          initialValues={dataCom}
          innerRef={refFormCom}
          onSubmit={(values, { setSubmitting }) => {
            values = {
              ...refFormShr.current?.values,
              ...values,
            };
            mutateCom(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionProductCompare />
          </Form>
        </Formik>
      )}
      <SavePageButton
        isLoading={
          isLoadingMttDtl ||
          isLoadingMttCat ||
          isLoadingMttShr ||
          isLoadingMttCom
        }
        onClick={() => {
          refFormShr.current?.submitForm();
          refFormCat.current?.submitForm();
          refFormCom.current?.submitForm();
          refFormDtl.current?.submitForm();
        }}
      />
    </>
  );
};

export default Product;
