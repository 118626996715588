import AuthContext from './AuthContext';
import useAuth from '../hooks/useAuth';

const AuthProvider = (props: React.PropsWithChildren<React.ReactNode>) => {
  const { user, authPing, authLogin, authLogout } = useAuth();
  return (
    <AuthContext.Provider value={{ user, authPing, authLogin, authLogout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
