import InputMultLang from 'components/smart/InputMultLang';
import InputFiles from 'components/smart/InputFiles';
import InputFile from 'components/smart/InputFile';
import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputTxtLink from 'components/smart/InputTxtLink';

const SectionFooter = () => {
  return (
    <PageSection sectionTitle="Footer">
      <Stack p="10px" spacing={3}>
        <InputFiles bucket="static" hasLink label="廣告" fieldPath="ad" />
        <InputFile bucket="static" label="權記LOGO" fieldPath="logoFooter" />
        <InputMultLang label="權記LOGO描述" fieldPath="logoFooterDescription" />
        <InputMultLang label={'版權文字'} fieldPath="copyright" />
        <InputTxtLink label={'條款及細則'} fieldPath="tnc" />
      </Stack>
    </PageSection>
  );
};
export default SectionFooter;
