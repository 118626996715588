import Header from 'components/smart/Header';
import { useContactQuery, usePageMutation } from 'hooks/useAppQuery';
import Contact from './Contact';
import Location from './Location';
import { Formik, Form } from 'formik';
import SectionMeta from 'components/smart/SectionMeta';
import SavePageButton from 'components/smart/SavePageButton';
import { useEffect } from 'react';
import { useSnackBar } from 'components/dumb/MuiSnackBar';

const ContactUs = () => {
  const { showSnackBar } = useSnackBar();
  const { isError, isLoading, data } = useContactQuery();
  const {
    isLoading: isLoadingMutation,
    mutate,
    isSuccess: isSuccessMutation,
    isError: isErrorMutation,
  } = usePageMutation('contact');

  useEffect(() => {
    if (isSuccessMutation) {
      showSnackBar('保存成功', 'success');
    }
    if (isErrorMutation) {
      showSnackBar('保存失敗', 'error');
    }
  }, [isSuccessMutation, isErrorMutation]);

  if (isLoading) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  if (isError) {
    return <p>Error found</p>;
  }

  return (
    <>
      <Header />
      {data && (
        <Formik
          validateOnChange={false}
          initialValues={data}
          onSubmit={(values, { setSubmitting }) => {
            mutate(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="聯絡我們" fieldPath="pageInfo" />
            <Location />
            <Contact />
            <SavePageButton isLoading={isLoadingMutation} />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ContactUs;
