import { TextField, Stack, Fab } from '@mui/material';
import useFormikArray from 'hooks/useFormikArray';
import { FastField, FieldProps } from 'formik';

interface IInputTexts {
  label: string;
  fieldPath: string;
  prefix: string;
  columns?: number;
}

export const InputTexts = (props: IInputTexts) => {
  const { label, fieldPath, prefix, columns } = props;
  const col = columns || 1;
  const { arrayFieldValue, addItem, removeItem } = useFormikArray(fieldPath);

  return (
    <Stack direction="row" mt="10px">
      <Stack flex="0 0 200px">
        <h3>{label}</h3>
      </Stack>
      <Stack flex="1 0 200px">
        <Stack direction="row" flexWrap="wrap">
          {arrayFieldValue &&
            arrayFieldValue.map((_: string, i: number) => {
              return (
                <Stack direction="row" key={i} flex={`0 0 ${100 / col}%`}>
                  <Stack
                    flex="1 0 100px"
                    justifyContent="center"
                    alignItems="center"
                    mt="16px"
                  >
                    <FastField name={`${fieldPath}[${i}]`}>
                      {(props: FieldProps) => (
                        <TextField
                          label={prefix ? prefix + (i + 1) : null}
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                  <Stack
                    flex="0 0 80px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Fab
                      color="default"
                      size="small"
                      sx={{ fontSize: '20px' }}
                      onClick={() => removeItem(i)}
                    >
                      &#10006;
                    </Fab>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          sx={{ margin: '10px 0 15px 0', fontSize: '20px' }}
          onClick={() => addItem('')}
        >
          +
        </Fab>
      </Stack>
    </Stack>
  );
};

export default InputTexts;
