import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputText from 'components/smart/InputText';

const SectionMailServer = () => {
  return (
    <PageSection sectionTitle="電郵伺服器">
      <Stack p="10px" spacing={3}>
        <InputText label="服務" fieldPath="mailServer.mailerService" />
        <InputText label="用戶" fieldPath="mailServer.mailerUser" />
        <InputText label="密碼" fieldPath="mailServer.mailerPassword" />
      </Stack>
    </PageSection>
  );
};

export default SectionMailServer;
