import { Stack } from '@mui/material';
import RqRte from 'components/dumb/RqRte';
import { FastField, FieldProps } from 'formik';
interface IMultRte {
  label: string;
  fieldPath: string;
}

const MultRte = (props: IMultRte) => {
  const { label, fieldPath } = props;
  return (
    <>
      <Stack>{label} (繁):</Stack>
      <FastField name={`${fieldPath}.tc`}>
        {(props: FieldProps) => (
          <RqRte
            text={props.field.value}
            onChange={props.field.onChange(props.field.name)}
          />
        )}
      </FastField>

      <Stack>{label} (簡):</Stack>
      <FastField name={`${fieldPath}.sc`}>
        {(props: FieldProps) => (
          <RqRte
            text={props.field.value}
            onChange={props.field.onChange(props.field.name)}
          />
        )}
      </FastField>

      <Stack>{label} (英):</Stack>
      <FastField name={`${fieldPath}.en`}>
        {(props: FieldProps) => (
          <RqRte
            text={props.field.value}
            onChange={props.field.onChange(props.field.name)}
          />
        )}
      </FastField>
    </>
  );
};

export default MultRte;
