import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputText from 'components/smart/InputText';

const SectionContact = () => {
  return (
    <PageSection sectionTitle="聯絡我們">
      <Stack p="10px" spacing={3}>
        <InputText label="查詢接收電郵" fieldPath="recipientEmailAddress" />
      </Stack>
    </PageSection>
  );
};

export default SectionContact;
