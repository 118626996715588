import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';
import InputFile from 'components/smart/InputFile';
import InputFiles from 'components/smart/InputFiles';

const SectionIcon = () => {
  return (
    <PageSection sectionTitle="網頁Icon">
      <Stack p="10px" spacing={3}>
        <InputFile label="Page Icon" fieldPath="icon.favicon" bucket="static" />
        <InputFiles
          label="App Icon"
          fieldPath="icon.appicon"
          bucket="static"
          hasDesc
        />
      </Stack>
    </PageSection>
  );
};

export default SectionIcon;
