import { Stack, Button, TextField, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import useValidation from 'hooks/useValidation';
import useAuth from 'hooks/useAuth';

const Login = () => {
  const isRegister = /[?&]register/.test(window.location.search);
  const navigate = useNavigate();
  const { validateAccountForm } = useValidation();
  const { user, authPing, authLogin, authRegister, isPinging, isSubmiting } =
    useAuth();

  useEffect(() => {
    if (user) {
      navigate('/general');
    } else {
      authPing();
    }
  }, [user]);

  if (isPinging) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  return (
    <Stack p="20px" alignItems="center" mt="10vh">
      <Card sx={{ minWidth: '240px', maxWidth: '640px', padding: '20px 50px' }}>
        <Stack maxWidth="400px" width="100%" alignItems="center">
          <Formik
            initialValues={{ name: '', pass: '', pass2: '' }}
            validate={(values) => validateAccountForm(isRegister, values)}
            onSubmit={(values, { setSubmitting }) => {
              if (isRegister) {
                authRegister(values.name, values.pass);
              } else {
                authLogin(values.name, values.pass);
              }
              setSubmitting(false);
            }}
          >
            <Form>
              <Stack spacing={2} alignItems="center">
                <h1>KKWatch CMS Login</h1>
                <Field name="name">
                  {(props: FieldProps) => (
                    <TextField
                      label="用戶名稱"
                      fullWidth
                      variant="outlined"
                      {...props.field}
                      error={
                        props.meta.touched && props.meta.error !== undefined
                      }
                      helperText={props.meta.touched && props.meta.error}
                    />
                  )}
                </Field>
                <Field name="pass">
                  {(props: FieldProps) => (
                    <TextField
                      label="密碼"
                      type="password"
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      {...props.field}
                      error={
                        props.meta.touched && props.meta.error !== undefined
                      }
                      helperText={props.meta.touched && props.meta.error}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter' && !isRegister) {
                          props.form.submitForm();
                        }
                      }}
                    />
                  )}
                </Field>
                {isRegister && (
                  <Field name="pass2">
                    {(props: FieldProps) => (
                      <TextField
                        label="確認密碼"
                        type="password"
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        {...props.field}
                        error={
                          props.meta.touched && props.meta.error !== undefined
                        }
                        helperText={props.meta.touched && props.meta.error}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter' && isRegister) {
                            props.form.submitForm();
                          }
                        }}
                      />
                    )}
                  </Field>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: '40%',
                    maxWidth: '100px',
                    backgroundColor: '#DE5A41',
                  }}
                  disabled={isSubmiting}
                >
                  {isRegister ? '註冊' : '登入'}
                </Button>
              </Stack>
            </Form>
          </Formik>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Login;
