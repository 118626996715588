import { useSnackBar } from 'components/dumb/MuiSnackBar';
import { useState } from 'react';
import { uploadFile } from 'services/api';

const useMinioUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const { showSnackBar } = useSnackBar();

  const upload = async (bucket: string, files: FileList | null) => {
    setIsUploading(true);
    try {
      if (bucket && files) {
        const data = await uploadFile(bucket, files);
        setIsUploading(false);
        showSnackBar('上傳成功', 'success');
        return data;
      }
    } catch (e) {
      console.error(e);
      showSnackBar('上傳失敗', 'error');
    }
    setIsUploading(false);
    return '';
  };

  return {
    isUploading,
    upload,
  };
};

export default useMinioUpload;
