import { Stack, Fab } from '@mui/material';
import { TextLink } from 'common/types';
import useFormikArray from 'hooks/useFormikArray';
import { makeid } from 'common/utils';
import InputTxtLink from 'components/smart/InputTxtLink';
interface IBreadcrumbProps {
  label: string;
  fieldPath: string;
}

const Breadcrumb = (props: IBreadcrumbProps) => {
  const { label, fieldPath } = props;
  const { arrayFieldValue, addItem, removeItem, moveDownItem, moveUpItem } =
    useFormikArray<TextLink>(fieldPath);
  const sampleTextLink = {
    _id: makeid(6),
    href: '',
    texts: {
      tc: '',
      sc: '',
      en: '',
    },
  } as TextLink;

  return (
    <Stack direction="row" mt="10px">
      <Stack flex="0 0 200px">
        <h3>{label}</h3>
      </Stack>
      <Stack flex="1 0 450px" spacing={2}>
        {arrayFieldValue.length > 0 &&
          arrayFieldValue.map((item: TextLink, i: number) => {
            return (
              <Stack
                direction="row"
                key={item._id}
                spacing={1}
                border="1px solid #ccc"
                borderRadius="10px"
                p="20px"
              >
                <Stack flex="1 0 450px">
                  <InputTxtLink
                    isArrayItem
                    hrefLabel={'路徑地址 ' + (i + 1)}
                    textLabel={'路徑文字 ' + (i + 1)}
                    fieldPath={`${fieldPath}[${i}]`}
                  />
                </Stack>
                <Stack
                  flex="0 0 80px"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Fab
                    color="default"
                    size="small"
                    sx={{ fontSize: '20px' }}
                    onClick={() => removeItem(i)}
                  >
                    &#10006;
                  </Fab>
                  {/* move up */}
                  {i !== 0 && (
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ fontSize: '15px' }}
                      onClick={() => moveUpItem(i)}
                    >
                      &#9650;
                    </Fab>
                  )}

                  {/* move down */}
                  {i < arrayFieldValue.length - 1 && (
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ fontSize: '15px' }}
                      onClick={() => moveDownItem(i)}
                    >
                      &#9660;
                    </Fab>
                  )}
                </Stack>
              </Stack>
            );
          })}
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          sx={{ margin: '10px 0 15px 0', fontSize: '20px' }}
          onClick={() => addItem(sampleTextLink)}
        >
          +
        </Fab>
      </Stack>
    </Stack>
  );
};

export default Breadcrumb;
