import { TextField, Stack } from '@mui/material';
import { FastField, FieldProps } from 'formik';

interface IInputText {
  label: string;
  fieldPath: string;
  size?: 'small' | 'medium';
  labelWidth?: string;
}

export const InputText = (props: IInputText) => {
  const { label, fieldPath, size, labelWidth } = props;
  const finalLabelWidth = labelWidth ? `0 0 ${labelWidth}` : '0 0 200px';
  return (
    <>
      <Stack direction="row" mt="10px">
        <Stack flex={finalLabelWidth}>
          <h3>{label}</h3>
        </Stack>
        <Stack flex="1 0 200px">
          <FastField name={fieldPath}>
            {(props: FieldProps) => (
              <TextField
                size={size}
                fullWidth
                autoComplete="off"
                variant="outlined"
                {...props.field}
              />
            )}
          </FastField>
        </Stack>
      </Stack>
    </>
  );
};

export default InputText;
