import ReactQuill, { Quill } from 'react-quill';
import { useRef, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';
import { uploadFile } from 'services/api';

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['16px', '18px', '20px', '24px'];
Quill.register(Size, true);

//https://stackoverflow.com/questions/38623716/how-to-add-custom-font-sizes-to-quilljs-editor
interface IRqRteProp {
  text: string;
  onChange: (html: string) => void;
  height?: string;
}

const RqRte = (props: IRqRteProp) => {
  const rq = useRef<ReactQuill>(null);
  const { text, onChange, height } = props;

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input.files) {
        // Save current cursor state
        if (rq.current) {
          const range = rq.current.getEditor().getSelection(true);
          const res = await uploadFile('static', input.files);
          rq.current.getEditor().deleteText(range.index, 1);
          rq.current.getEditor().insertEmbed(range.index, 'image', res);
          rq.current.getEditor().setSelection(range.index + 1, 1);
        }
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          [{ size: Size.whitelist }],
          ['bold', 'italic', 'underline', 'strike',  'blockquote'],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          [
            { background: [] },
            {
              color: [
                '#000000',
                '#FFFFFF',
                '#FF0000',
                '#00FF00',
                '#0000FF',
                '#FFFF00',
                '#00FFFF',
                '#FF00FF',
                '#C0C0C0',
                '#808080',
                '#800000',
                '#808000',
                '#008000',
                '#800080',
                '#008080',
                '#000080',
                '#6F342A',
              ],
            },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = useMemo(
    () => [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'script',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'background',
      'color',
      'video',
      'size',
    ],
    []
  );

  return (
    <ReactQuill
      ref={rq}
      value={text}
      onChange={onChange}
      modules={modules}
      formats={formats}
      style={{ height, marginBottom: '45px' }}
    />
  );
};

export default RqRte;
