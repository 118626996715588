import { Stack, Button, TextField } from '@mui/material';
import { FileItem } from 'common/types';
import useMinioUpload from 'hooks/useMinioUpload';
import { FastField, FieldProps, useField } from 'formik';

interface IInputFile {
  label: string;
  bucket: string;
  fieldPath: string;
  hasLink?: boolean;
  hasDesc?: boolean;
}

const InputFile = (props: IInputFile) => {
  const { label, hasLink, hasDesc, fieldPath, bucket } = props;
  const { isUploading, upload } = useMinioUpload();
  const [field, _, helper] = useField(fieldPath);

  const handleUploadFile = async (files: FileList | null) => {
    const res = await upload(bucket, files);
    const newValue: FileItem = {
      ...field.value,
      src: res,
    };
    helper.setValue(newValue);
  };

  return (
    <>
      <Stack direction="row" alignItems="flex-start">
        <Stack flex="0 0 200px">
          <h3>{label}</h3>
        </Stack>

        <Stack flex="1 0 450px" direction="column">
          <Stack direction="column" spacing={3}>
            <Stack direction="row">
              <Stack flex="1 0 100px" direction="column" spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    component="label"
                    disabled={isUploading}
                  >
                    上傳圖片
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleUploadFile(e.target.files)}
                    />
                  </Button>
                  <FastField name={`${fieldPath}.src`}>
                    {(props: FieldProps) => (
                      <TextField
                        sx={{ flex: '1 0 200px' }}
                        size="small"
                        label="或直接貼上圖片地址"
                        autoComplete="off"
                        variant="outlined"
                        {...props.field}
                      />
                    )}
                  </FastField>
                </Stack>
                {hasLink && (
                  <Stack>
                    <FastField name={`${fieldPath}.href`}>
                      {(props: FieldProps) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="URL"
                          autoComplete="off"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                )}
                {hasDesc && (
                  <Stack>
                    <FastField name={`${fieldPath}.desc`}>
                      {(props: FieldProps) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="圖片描述"
                          autoComplete="off"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default InputFile;
