import { Stack, Button, TextField, Fab } from '@mui/material';
import { BannerItem } from 'common/types';
import useFormikArray from 'hooks/useFormikArray';
import useMinioUpload from 'hooks/useMinioUpload';
import { FastField, FieldProps } from 'formik';
import { makeid } from 'common/utils';

interface IBanner {
  label: string;
  fieldPath: string;
}

const Banner = (props: IBanner) => {
  const { label, fieldPath } = props;
  const { arrayFieldValue, addItem, removeItem, moveDownItem, moveUpItem } =
    useFormikArray(fieldPath);
  const { isUploading, upload } = useMinioUpload();

  return (
    <Stack direction="row">
      <Stack flex="0 0 200px">
        <h3>{label}</h3>
      </Stack>
      <Stack flex="1 0 200px" direction="column">
        <Stack direction="column" spacing={3}>
          {arrayFieldValue.map((bannerItem: BannerItem, i: number) => {
            return (
              <Stack
                direction="row"
                key={bannerItem._id}
                p="20px"
                style={{ border: '1px solid #888', borderRadius: '10px' }}
              >
                <Stack flex="1 0 100px" direction="column" spacing={2}>
                  {/* src */}
                  <Stack direction="row" spacing={1}>
                    <FastField name={`${fieldPath}[${i}].src.tc`}>
                      {(props: FieldProps) => (
                        <>
                          <Button
                            variant="contained"
                            component="label"
                            disabled={isUploading}
                          >
                            上傳圖片{i + 1} (繁)
                            <input
                              type="file"
                              hidden
                              onChange={async (e) => {
                                const res = await upload(
                                  'banners',
                                  e.target.files
                                );
                                if (res)
                                  props.form.setFieldValue(
                                    `${fieldPath}[${i}].src`,
                                    res
                                  );
                              }}
                            />
                          </Button>

                          <TextField
                            sx={{ flex: '1 0 200px' }}
                            size="small"
                            label="或直接貼上圖片地址"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        </>
                      )}
                    </FastField>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <FastField name={`${fieldPath}[${i}].src.sc`}>
                      {(props: FieldProps) => (
                        <>
                          <Button
                            variant="contained"
                            component="label"
                            disabled={isUploading}
                          >
                            上傳圖片{i + 1} (簡)
                            <input
                              type="file"
                              hidden
                              onChange={async (e) => {
                                const res = await upload(
                                  'banners',
                                  e.target.files
                                );
                                if (res)
                                  props.form.setFieldValue(
                                    `${fieldPath}[${i}].src`,
                                    res
                                  );
                              }}
                            />
                          </Button>

                          <TextField
                            sx={{ flex: '1 0 200px' }}
                            size="small"
                            label="或直接貼上圖片地址"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        </>
                      )}
                    </FastField>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <FastField name={`${fieldPath}[${i}].src.en`}>
                      {(props: FieldProps) => (
                        <>
                          <Button
                            variant="contained"
                            component="label"
                            disabled={isUploading}
                          >
                            上傳圖片{i + 1} (英)
                            <input
                              type="file"
                              hidden
                              onChange={async (e) => {
                                const res = await upload(
                                  'banners',
                                  e.target.files
                                );
                                if (res)
                                  props.form.setFieldValue(
                                    `${fieldPath}[${i}].src`,
                                    res
                                  );
                              }}
                            />
                          </Button>

                          <TextField
                            sx={{ flex: '1 0 200px' }}
                            size="small"
                            label="或直接貼上圖片地址"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        </>
                      )}
                    </FastField>
                  </Stack>
                  {/* Banner連結 */}
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack flex="0 0 100px">Banner連結</Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].href.tc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="繁"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].href.sc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="簡"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].href.en`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="英"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>
                  {/* 標題 */}
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack flex="0 0 100px">標題</Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].title.tc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="繁"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].title.sc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="簡"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].title.en`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="英"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack flex="0 0 100px">副標題</Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].subTitle.tc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="繁"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].subTitle.sc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="簡"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].subTitle.en`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="英"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack flex="0 0 100px">描述</Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].desc.tc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="繁"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].desc.sc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="簡"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].desc.en`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="英"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack flex="0 0 100px">按鈕文字</Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].buttonText.tc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="繁"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].buttonText.sc`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="簡"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                    <Stack flex="1 0 100px">
                      <FastField name={`${fieldPath}[${i}].buttonText.en`}>
                        {(props: FieldProps) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="英"
                            autoComplete="off"
                            variant="outlined"
                            {...props.field}
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  flex="0 0 80px"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Fab
                    color="default"
                    size="small"
                    sx={{ fontSize: '20px' }}
                    onClick={() => removeItem(i)}
                  >
                    &#10006;
                  </Fab>
                  {/* move up */}
                  {i !== 0 && (
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ fontSize: '15px' }}
                      onClick={() => moveUpItem(i)}
                    >
                      &#9650;
                    </Fab>
                  )}

                  {/* move down */}
                  {i < arrayFieldValue.length - 1 && (
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ fontSize: '15px' }}
                      onClick={() => moveDownItem(i)}
                    >
                      &#9660;
                    </Fab>
                  )}
                </Stack>
              </Stack>
            );
          })}
        </Stack>

        <Stack>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            sx={{ margin: '10px 0 15px 0', fontSize: '20px' }}
            onClick={() => addItem({
              _id: makeid(6),
              src: { tc: '', sc: '', en: '' },
              href: { tc: '', sc: '', en: '' },
              title: { tc: '', sc: '', en: '' },
              subTitle: { tc: '', sc: '', en: '' },
              desc: { tc: '', sc: '', en: '' },
              buttonText: { tc: '', sc: '', en: '' },
            })}
          >
            +
          </Fab>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Banner;
