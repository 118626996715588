import { Fab, Stack } from '@mui/material';

interface ISavePageButton {
  isLoading: boolean;
  onClick?: () => void;
}

const SavePageButton = (props: ISavePageButton) => {
  const { isLoading, onClick } = props;

  return (
    <Stack alignItems="center" height="50px">
      <Fab
        type="submit"
        disabled={isLoading}
        variant="extended"
        size="small"
        onClick={onClick}
        sx={{
          fontSize: '20px',
          padding: '20px',
          position: 'fixed',
          bottom: '30px',
          color: '#fff',
          backgroundColor: '#2F5369',
          zIndex: 99,
        }}
      >
        保存設定
      </Fab>
    </Stack>
  );
};

export default SavePageButton;
