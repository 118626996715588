import { Stack } from '@mui/material';
import { FastField, FieldProps } from 'formik';

interface IInputTxtArea {
  label: string;
  fieldPath: string;
  labelWidth?: string;
  height?: number;
}

export const InputTxtArea = (props: IInputTxtArea) => {
  const { label, fieldPath, labelWidth, height } = props;
  const finalLabelWidth = labelWidth ? `0 0 ${labelWidth}` : '0 0 200px';
  const finalHeight = height ? height : 10;
  return (
    <>
      <Stack direction="row" mt="10px">
        <Stack flex={finalLabelWidth}>
          <h3>{label}</h3>
        </Stack>
        <Stack flex="1 0 200px">
          <FastField name={fieldPath}>
            {(props: FieldProps) => (
              <textarea
                rows={finalHeight}
                autoComplete="off"
                {...props.field}
              />
            )}
          </FastField>
        </Stack>
      </Stack>
    </>
  );
};

export default InputTxtArea;
