import { Stack } from '@mui/material';
import PageSection from 'components/smart/PageSection';
import InputTexts from 'components/smart/InputTexts';
import InputMultLang from 'components/smart/InputMultLang';

const SectionProducts = () => {
  return (
    <PageSection sectionTitle="貨品展示">
      <Stack spacing={3}>
        <InputMultLang
          label="最新貨品文字"
          fieldPath="productsTexts.newProducts"
        />
        <InputTexts
          prefix="貨品"
          label="最新貨品 (參考編號)"
          fieldPath="products.newProducts"
        />
        <InputMultLang
          label="推薦貨品文字"
          fieldPath="productsTexts.recommendProducts"
        />
        <InputTexts
          columns={2}
          prefix="貨品"
          label="推薦貨品 (參考編號)"
          fieldPath="products.recommendProducts"
        />
      </Stack>
    </PageSection>
  );
};

export default SectionProducts;
