import { useEffect, useRef } from 'react';
import Header from 'components/smart/Header';
import SectionHeader from 'pages/General/SectionHeader';
import SectionFooter from 'pages/General/SectionFooter';
import SavePageButton from 'components/smart/SavePageButton';
import { useSnackBar } from 'components/dumb/MuiSnackBar';
import {
  useHeaderQuery,
  useFooterQuery,
  usePageMutation,
} from 'hooks/useAppQuery';
import { Form, Formik, FormikValues, FormikProps } from 'formik';
import { MenuItem } from 'common/types';

const General = () => {
  const { showSnackBar } = useSnackBar();

  const refFormHeader = useRef<FormikProps<FormikValues>>(null);
  const refFormFooter = useRef<FormikProps<FormikValues>>(null);
  const {
    isError: isErrHeader,
    isLoading: isLoadingHeader,
    data: dataHeader,
  } = useHeaderQuery();
  const {
    isError: isErrFooter,
    isLoading: isLoadingFooter,
    data: dataFooter,
  } = useFooterQuery();

  const {
    isLoading: isLoadingMttHeader,
    mutate: mutateHeader,
    isSuccess: isSuccessMttHeader,
    isError: isErrorMttHeader,
  } = usePageMutation('header');

  const {
    isLoading: isLoadingMttFooter,
    mutate: mutateFooter,
    isSuccess: isSuccessMttFooter,
    isError: isErrorMttFooter,
  } = usePageMutation('footer');

  useEffect(() => {
    if (isSuccessMttFooter && isSuccessMttHeader)
      showSnackBar('保存成功', 'success');
    if (isErrorMttHeader || isErrorMttFooter) showSnackBar('保存失敗', 'error');
  }, [
    isSuccessMttFooter,
    isSuccessMttHeader,
    isErrorMttHeader,
    isErrorMttFooter,
  ]);

  if (isLoadingHeader || isLoadingFooter) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  if (isErrHeader || isErrFooter) {
    return <p>Error found</p>;
  }

  return (
    <>
      <Header />
      {dataHeader && (
        <Formik
          validateOnChange={false}
          initialValues={dataHeader}
          innerRef={refFormHeader}
          onSubmit={(values, { setSubmitting }) => {
            mutateHeader(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionHeader />
          </Form>
        </Formik>
      )}
      {dataFooter && (
        <Formik
          validateOnChange={false}
          initialValues={dataFooter}
          innerRef={refFormFooter}
          onSubmit={(values, { setSubmitting }) => {
            const completeFooter = {
              ...values,
              emailFooter: refFormHeader.current?.values.email,
              telephoneFooter: refFormHeader.current?.values.telephone,
              addressFooter: refFormHeader.current?.values.address,
              toolbarFooter: {
                media: refFormHeader.current?.values.toolbar.media,
                translation: refFormHeader.current?.values.toolbar.translation,
              },
              menuFooter: refFormHeader.current?.values.menu.filter(
                (f: MenuItem) => f.isShownInFooter
              ),
            };
            mutateFooter(completeFooter);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionFooter />
          </Form>
        </Formik>
      )}
      <SavePageButton
        isLoading={isLoadingMttHeader || isLoadingMttFooter}
        onClick={() => {
          refFormHeader.current?.submitForm();
          refFormFooter.current?.submitForm();
        }}
      />
      ;
    </>
  );
};

export default General;
