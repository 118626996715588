import { TextField, Stack, Fab } from '@mui/material';
import { MultLang } from 'common/types';
import { makeid } from 'common/utils';
import { FastField, FieldProps } from 'formik';
import useFormikArray from 'hooks/useFormikArray';

interface IInputMultLangs {
  label: string;
  fieldPath: string;
}

const InputMultLangs = (props: IInputMultLangs) => {
  const { label, fieldPath } = props;
  const { arrayFieldValue, addItem, removeItem } = useFormikArray(fieldPath);

  return (
    <>
      <Stack direction="row">
        <Stack flex="0 0 200px">
          <h3>{label}</h3>
        </Stack>
        <Stack flex="1 0 450px">
          <Stack spacing={2}>
            {arrayFieldValue &&
              arrayFieldValue.map((val: MultLang, i: number) => (
                <Stack key={val._id || makeid(6)} direction="row" spacing={2}>
                  <Stack flex="1 0 100px">
                    <FastField name={`${fieldPath}[${i}].tc`}>
                      {(props: FieldProps) => (
                        <TextField
                          fullWidth
                          autoComplete="off"
                          label="繁"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                  <Stack flex="1 0 100px">
                    <FastField name={`${fieldPath}[${i}].sc`}>
                      {(props: FieldProps) => (
                        <TextField
                          fullWidth
                          autoComplete="off"
                          label="簡"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                  <Stack flex="1 0 100px">
                    <FastField name={`${fieldPath}[${i}].en`}>
                      {(props: FieldProps) => (
                        <TextField
                          fullWidth
                          autoComplete="off"
                          label="英"
                          variant="outlined"
                          {...props.field}
                        />
                      )}
                    </FastField>
                  </Stack>
                  <Stack
                    flex="0 0 80px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Fab
                      color="default"
                      size="small"
                      sx={{ fontSize: '20px' }}
                      onClick={() => removeItem(i)}
                    >
                      &#10006;
                    </Fab>
                    {/* {i !== 0 && (
                    <button type="button" onClick={() => moveUpItem(i)}>
                      up
                    </button>
                  )}
                  {i < arrayFieldValue.length - 1 && (
                    <button type="button" onClick={() => moveDownItem(i)}>
                      down
                    </button>
                  )} */}
                  </Stack>
                </Stack>
              ))}

            <Fab
              color="primary"
              aria-label="add"
              size="small"
              sx={{ margin: '10px 0 15px 0', fontSize: '20px' }}
              onClick={() =>
                addItem({
                  _id: makeid(6),
                  tc: '',
                  sc: '',
                  en: '',
                })
              }
            >
              +
            </Fab>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default InputMultLangs;
