import PageSection from 'components/smart/PageSection';
import InputFile from 'components/smart/InputFile';
import { Stack } from '@mui/material';
import Banner from 'pages/Home/Banner';

const SectionBanner = () => {
  return (
    <PageSection sectionTitle="Banner">
      <Stack p="10px" spacing={3}>
        <Banner label="首頁 Banner" fieldPath="banners.homeBanner" />
        <InputFile
          bucket="banners"
          hasLink
          label="Popup Banner"
          fieldPath="banners.popupBanner"
        />
      </Stack>
    </PageSection>
  );
};

export default SectionBanner;
