import { useState, useCallback } from 'react';
import { CmsUser } from 'common/types';
import { login, logout, pingAuth, register, reset } from 'services/api';
import { useSnackBar } from 'components/dumb/MuiSnackBar';

export const useAuth = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isPinging, setIsPinging] = useState(true);
  const [user, setUser] = useState<CmsUser | null>(null);
  const { showSnackBar } = useSnackBar();

  const authLogin = useCallback(async (username: string, password: string) => {
    setIsSubmiting(true);
    const resUser = await login(username, password);
    if (resUser) {
      setUser(resUser);
      showSnackBar('登入成功', 'success');
    } else {
      showSnackBar('登入失敗', 'error');
    }
    setIsSubmiting(false);
    return resUser;
  }, []);

  const authRegister = useCallback(
    async (username: string, password: string) => {
      setIsSubmiting(true);
      const resUser = await register(username, password);
      if (resUser) {
        showSnackBar('註冊成功', 'success');
      } else {
        showSnackBar('註冊失敗', 'error');
      }
      setIsSubmiting(false);
      return resUser;
    },
    []
  );

  const authReset = useCallback(async (oldPass: string, newPass: string) => {
    setIsSubmiting(true);
    const resError = await reset(oldPass, newPass);
    if (resError) {
      showSnackBar('更改密碼失敗:' + resError.message, 'error');
    } else {
      showSnackBar('成功更改密碼', 'success');
      authLogout();
    }
    setIsSubmiting(false);
  }, []);

  const authLogout = useCallback(async () => {
    await logout();
    setUser(null);
    window.location.href = '/';
    return true;
  }, []);

  const authPing = useCallback(async () => {
    setIsPinging(true);
    const resUser = await pingAuth();
    if (resUser) {
      setUser(resUser);
      return resUser;
    }
    setIsPinging(false);
    return null;
  }, []);

  return {
    isPinging,
    isSubmiting,
    user,
    authPing,
    authLogin,
    authLogout,
    authRegister,
    authReset,
  };
};

export default useAuth;
