import { Stack } from '@mui/material';
import PageSection from 'components/smart/PageSection';
import InputMultLang from 'components/smart/InputMultLang';
import SectionMeta from 'components/smart/SectionMeta';

const SectionProductCategory = () => {
  return (
    <>
      <SectionMeta title="產品目錄" fieldPath="pageInfo" />
      <PageSection sectionTitle="產品目錄文字">
        <Stack p="10px" spacing={3}>
          <InputMultLang
            label="搜尋貨物框"
            fieldPath="searchInfoTexts.filterPanelTitle"
          />
          <InputMultLang
            label="搜尋按鈕"
            fieldPath="searchInfoTexts.searchButton"
          />
           <InputMultLang
            label="清除按鈕"
            fieldPath="searchInfoTexts.clearButton"
          />
          <InputMultLang
            label="搜尋結果"
            fieldPath="searchInfoTexts.resultInfo"
          />
          <InputMultLang
            label="沒有搜尋結果"
            fieldPath="searchInfoTexts.resultNone"
          />
          <InputMultLang
            label="搜尋結果全部"
            fieldPath="searchInfoTexts.resultAllStock"
          />
          <InputMultLang
            label="搜尋結果現貨"
            fieldPath="searchInfoTexts.resultInStock"
          />
          <InputMultLang
            label="搜尋結果缺貨"
            fieldPath="searchInfoTexts.resultOutOfStock"
          />
          {/* watchInfoTexts */}
          <InputMultLang label="手錶-公司編號" fieldPath="watchInfoTexts._id" />
          <InputMultLang
            label="手錶-品牌"
            fieldPath="watchInfoTexts.brand_id"
          />
          <InputMultLang
            label="手錶-型號"
            fieldPath="watchInfoTexts.model_number"
          />
          <InputMultLang
            label="手錶-價錢"
            fieldPath="watchInfoTexts.price_show"
          />

          {/* filtersLabels */}

          <InputMultLang
            label="搜尋條件-外圈"
            fieldPath="filtersLabels.bezel_type"
          />
          <InputMultLang
            label="搜尋條件-錶殼材料"
            fieldPath="filtersLabels.body_type"
          />
          <InputMultLang
            label="搜尋條件-錶帶"
            fieldPath="filtersLabels.bracelet_type"
          />
          <InputMultLang
            label="搜尋條件-品牌"
            fieldPath="filtersLabels.brand"
          />
          <InputMultLang
            label="搜尋條件-面盤顏色"
            fieldPath="filtersLabels.dial_color"
          />
          <InputMultLang
            label="搜尋條件-面盤"
            fieldPath="filtersLabels.dial_type"
          />
          <InputMultLang
            label="搜尋條件-手錶類型"
            fieldPath="filtersLabels.feature_type"
          />
          <InputMultLang
            label="搜尋條件-刻度字款"
            fieldPath="filtersLabels.maker_type"
          />
          <InputMultLang
            label="搜尋條件-機芯"
            fieldPath="filtersLabels.mech_type"
          />
          <InputMultLang
            label="搜尋條件-手錶尺碼"
            fieldPath="filtersLabels.size_type"
          />
          <InputMultLang
            label="搜尋條件-現貨供應"
            fieldPath="filtersLabels.supply_status"
          />
          <InputMultLang
            label="搜尋條件-關鍵字"
            fieldPath="filtersLabels.keywords"
          />
          <InputMultLang
            label="搜尋條件-原廠證明"
            fieldPath="filtersLabels.mfrProof"
          />
          <InputMultLang
            label="搜尋條件-盒"
            fieldPath="filtersLabels.packageBox"
          />
          <InputMultLang
            label="搜尋條件-沒有資料"
            fieldPath="filtersLabels.noOptions"
          />

          <InputMultLang
            label='搜尋條件-銷售地點'
            fieldPath="filtersLabels.locations"
          />

          {/* bottem part TODO check where use it or not */}
          {/* <InputMultLang label="瀏覽紀錄" fieldPath="browseHistoryText" />
          <InputMultLang label="相關產品" fieldPath="relatedProductsText" /> */}
        </Stack>
      </PageSection>
    </>
  );
};
export default SectionProductCategory;
