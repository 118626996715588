import { InputMultLang } from 'components/smart/InputMultLang';
import { InputText } from 'components/smart/InputText';
import { Stack } from '@mui/material';

interface IInputTxtLink {
  label?: string;
  fieldPath: string;
  hrefLabel?: string;
  textLabel?: string;
  isArrayItem?: boolean;
}

const InputTxtLink = (props: IInputTxtLink) => {
  const { label, fieldPath, hrefLabel, textLabel, isArrayItem } = props;
  const borderStyle = isArrayItem === undefined ? '1px solid #ccc' : 'none';
  const paddingStyle = isArrayItem === undefined ? '20px' : '0';
  const mtStyle = isArrayItem === undefined ? '10px' : '0';

  const finalHrefLbl = hrefLabel ? hrefLabel : '連結地址';
  const finalTextLbl = textLabel ? textLabel : '連結文字';

  return (
    <Stack direction="row" mt={mtStyle}>
      {label && (
        <Stack flex="0 0 200px">
          <h3>{label}</h3>
        </Stack>
      )}
      {/* <Stack flex="1 0 450px" spacing={3}> */}
      <Stack
        flex="1 0 450px"
        spacing={2}
        border={borderStyle}
        borderRadius="10px"
        p={paddingStyle}
      >
        <InputText
          label={finalHrefLbl}
          fieldPath={`${fieldPath}.href`}
          size="small"
          labelWidth="100px"
        />
        <InputMultLang
          label={finalTextLbl}
          fieldPath={`${fieldPath}.texts`}
          size="small"
          labelWidth="100px"
        />
      </Stack>
      {/*  </Stack> */}
    </Stack>
  );
};

export default InputTxtLink;
