import { useEffect, useRef } from 'react';
import Header from 'components/smart/Header';
import SectionMeta from 'components/smart/SectionMeta';
import SavePageButton from 'components/smart/SavePageButton';
import {
  useCompanyQuery,
  useServicesQuery,
  useFaqQuery,
  usePaymentQuery,
  useTncQuery,
  usePageMutation,
} from 'hooks/useAppQuery';
import { Form, Formik, FormikValues, FormikProps } from 'formik';
import { useSnackBar } from 'components/dumb/MuiSnackBar';
import SectionTextPage from './SectionTextPage';

const CompanyAndServices = () => {
  const { showSnackBar } = useSnackBar();

  const refFormCom = useRef<FormikProps<FormikValues>>(null);
  const refFormSvc = useRef<FormikProps<FormikValues>>(null);
  const refFormPmnt = useRef<FormikProps<FormikValues>>(null);
  const refFormFaq = useRef<FormikProps<FormikValues>>(null);
  const refFormTnc = useRef<FormikProps<FormikValues>>(null);

  const {
    isError: isErrCom,
    isLoading: isLoadingCom,
    data: dataCom,
  } = useCompanyQuery();
  const {
    isError: isErrSvc,
    isLoading: isLoadingSvc,
    data: dataSvc,
  } = useServicesQuery();
  const {
    isError: isErrPmnt,
    isLoading: isLoadingPmnt,
    data: dataPmnt,
  } = usePaymentQuery();
  const {
    isError: isErrFaq,
    isLoading: isLoadingFaq,
    data: dataFaq,
  } = useFaqQuery();
  const {
    isError: isErrTnc,
    isLoading: isLoadingTnc,
    data: dataTnc,
  } = useTncQuery();

  const {
    isLoading: isLoadingMttCom,
    mutate: mutateCom,
    isSuccess: isMttSuccessCom,
    isError: isMttErrorCom,
  } = usePageMutation('company');
  const {
    isLoading: isLoadingMttSvc,
    mutate: mutateSvc,
    isSuccess: isMttSuccessSvc,
    isError: isMttErrorSvc,
  } = usePageMutation('services');
  const {
    isLoading: isLoadingMttPmnt,
    mutate: mutatePmnt,
    isSuccess: isMttSuccessPmnt,
    isError: isMttErrorPmnt,
  } = usePageMutation('payment');
  const {
    isLoading: isLoadingMttFaq,
    mutate: mutateFaq,
    isSuccess: isMttSuccessFaq,
    isError: isMttErrorFaq,
  } = usePageMutation('faq');
  const {
    isLoading: isLoadingMttTnc,
    mutate: mutateTnc,
    isSuccess: isMttSuccessTnc,
    isError: isMttErrorTnc,
  } = usePageMutation('tnc');

  useEffect(() => {
    if (
      isMttSuccessSvc &&
      isMttSuccessCom &&
      isMttSuccessFaq &&
      isMttSuccessPmnt &&
      isMttSuccessTnc
    )
      showSnackBar('保存成功', 'success');
    if (
      isMttErrorCom ||
      isMttErrorSvc ||
      isMttErrorFaq ||
      isMttErrorPmnt ||
      isMttErrorTnc
    )
      showSnackBar('保存失敗', 'error');
  }, [
    isMttSuccessSvc,
    isMttSuccessCom,
    isMttErrorCom,
    isMttErrorSvc,
    isMttErrorTnc,
  ]);

  if (
    isLoadingCom ||
    isLoadingSvc ||
    isLoadingFaq ||
    isLoadingPmnt ||
    isLoadingTnc
  ) {
    return <div style={{ textAlign: 'center' }}>loading...</div>;
  }
  if (isErrCom || isErrSvc || isErrFaq || isErrPmnt || isErrTnc) {
    return <p>Error found</p>;
  }

  return (
    <>
      <Header />
      {dataCom && (
        <Formik
          validateOnChange={false}
          initialValues={dataCom}
          innerRef={refFormCom}
          onSubmit={(values, { setSubmitting }) => {
            mutateCom(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="公司" fieldPath="pageInfo" />
            <SectionTextPage title="公司" fieldPath="html" />
          </Form>
        </Formik>
      )}
      {dataSvc && (
        <Formik
          innerRef={refFormSvc}
          validateOnChange={false}
          initialValues={dataSvc}
          onSubmit={(values, { setSubmitting }) => {
            mutateSvc(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="服務" fieldPath="pageInfo" />
            <SectionTextPage title="服務" fieldPath="html" />
          </Form>
        </Formik>
      )}
      {dataFaq && (
        <Formik
          innerRef={refFormFaq}
          validateOnChange={false}
          initialValues={dataFaq}
          onSubmit={(values, { setSubmitting }) => {
            mutateFaq(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="常見問題" fieldPath="pageInfo" />
            <SectionTextPage title="常見問題" fieldPath="html" />
          </Form>
        </Formik>
      )}
      {dataPmnt && (
        <Formik
          innerRef={refFormPmnt}
          validateOnChange={false}
          initialValues={dataPmnt}
          onSubmit={(values, { setSubmitting }) => {
            mutatePmnt(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="付款方法" fieldPath="pageInfo" />
            <SectionTextPage title="付款方法" fieldPath="html" />
          </Form>
        </Formik>
      )}
      {dataTnc && (
        <Formik
          innerRef={refFormTnc}
          validateOnChange={false}
          initialValues={dataTnc}
          onSubmit={(values, { setSubmitting }) => {
            mutatePmnt(values);
            setSubmitting(false);
          }}
        >
          <Form>
            <SectionMeta title="條款及細則" fieldPath="pageInfo" />
            <SectionTextPage title="條款及細則" fieldPath="html" />
          </Form>
        </Formik>
      )}
      <SavePageButton
        isLoading={
          isLoadingMttCom ||
          isLoadingMttSvc ||
          isLoadingMttFaq ||
          isLoadingMttPmnt ||
          isLoadingMttTnc
        }
        onClick={() => {
          refFormCom.current?.submitForm();
          refFormSvc.current?.submitForm();
          refFormFaq.current?.submitForm();
          refFormPmnt.current?.submitForm();
          refFormTnc.current?.submitForm();
        }}
      />
    </>
  );
};

export default CompanyAndServices;
