import { useField } from 'formik';

const useFormikArray = <T>(fieldName: string) => {
  //disable typescript-eslint/no-unused-vars
  const [fd, _, helper] = useField(fieldName);
  const arrayFieldValue = fd.value;

  const addItem = (sample: T) => {
    if (arrayFieldValue) {
      helper.setValue([...arrayFieldValue, sample]);
    } else {
      helper.setValue([sample]);
    }
  };

  const removeItem = (idx: number) => {
    helper.setValue(arrayFieldValue.filter((_: T, fid: number) => idx !== fid));
  };

  const moveUpItem = (idx: number) => {
    const temp = { ...arrayFieldValue[idx] };
    arrayFieldValue[idx] = { ...arrayFieldValue[idx - 1] };
    arrayFieldValue[idx - 1] = temp;
    helper.setValue(arrayFieldValue);
  };

  const moveDownItem = (idx: number) => {
    const temp = { ...arrayFieldValue[idx] };
    arrayFieldValue[idx] = { ...arrayFieldValue[idx + 1] };
    arrayFieldValue[idx + 1] = temp;
    helper.setValue(arrayFieldValue);
  };

  return {
    arrayFieldValue,
    addItem,
    removeItem,
    moveUpItem,
    moveDownItem,
  };
};

export default useFormikArray;
