import Header from 'components/smart/Header';
import { useState } from 'react';
import RqRte from 'components/dumb/RqRte';
import { Stack } from '@mui/material';

const Demo = () => {
  const [rqText, setRqText] = useState(``);
  return (
    <>
      <Header />
      <Stack direction="row">
        <Stack p="10px" flex="1 0 600px">
          <RqRte text={rqText} onChange={setRqText} height="360px" />
        </Stack>
        <Stack p="10px" flex="1 0 600px">
          <h1>Html Code:</h1>
          <div
            style={{
              border: '1px solid #000',
              margin: '20px',
              minHeight: '50px',
            }}
          >
            {rqText}
          </div>
        </Stack>
      </Stack>
      <hr />
      <h1>Preview:</h1>
      <div
        className="rq-text"
        style={{
          border: '1px solid #000',
          margin: '20px',
        }}
        dangerouslySetInnerHTML={{ __html: rqText }}
      ></div>
    </>
  );
};

export default Demo;
