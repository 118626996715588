import {
  Stack,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { MenuItem } from 'common/types';
import { makeid } from 'common/utils';
import useFormikArray from 'hooks/useFormikArray';
import { FastField, FieldProps } from 'formik';
interface IMenu {
  label: string;
  fieldPath: string;
}

const Menu = (props: IMenu) => {
  const { label, fieldPath } = props;
  const { arrayFieldValue, addItem, removeItem, moveUpItem, moveDownItem } =
    useFormikArray(fieldPath);

  const getSampleMenuItem = () => {
    return {
      _id: makeid(6),
      text: {
        tc: '',
        sc: '',
        en: '',
      },
      link: '',
      parentLink: '',
      isSubMenu: false,
      isShownInFooter: false,
    } as MenuItem;
  };

  return (
    <>
      <Stack direction="row" mt="10px">
        <Stack flex="0 0 200px">
          <h3>{label}</h3>
        </Stack>

        <Stack flex="1 0 450px" direction="column">
          <Stack direction="column" spacing={2}>
            {arrayFieldValue.map((m: MenuItem, i: number) => {
              return (
                <Stack
                  direction="row"
                  key={m._id}
                  p="20px"
                  sx={{ border: '1px solid #888', borderRadius: '10px' }}
                >
                  {/* fill the left part for right remove button */}
                  <Stack flex="1 0 100px" direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                      <Stack flex="1 0 100px">
                        <FastField name={`${fieldPath}[${i}].text.tc`}>
                          {(props: FieldProps) => (
                            <TextField
                              size="small"
                              fullWidth
                              autoComplete="off"
                              label="繁"
                              variant="outlined"
                              {...props.field}
                            />
                          )}
                        </FastField>
                      </Stack>
                      <Stack flex="1 0 100px">
                        <FastField name={`${fieldPath}[${i}].text.sc`}>
                          {(props: FieldProps) => (
                            <TextField
                              size="small"
                              fullWidth
                              autoComplete="off"
                              label="簡"
                              variant="outlined"
                              {...props.field}
                            />
                          )}
                        </FastField>
                      </Stack>
                      <Stack flex="1 0 100px">
                        <FastField name={`${fieldPath}[${i}].text.en`}>
                          {(props: FieldProps) => (
                            <TextField
                              size="small"
                              fullWidth
                              autoComplete="off"
                              label="英"
                              variant="outlined"
                              {...props.field}
                            />
                          )}
                        </FastField>
                      </Stack>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                      <Stack flex="1 0 200px">
                        <FastField name={`${fieldPath}[${i}].link`}>
                          {(props: FieldProps) => (
                            <TextField
                              size="small"
                              autoComplete="off"
                              label="ID"
                              variant="outlined"
                              {...props.field}
                            />
                          )}
                        </FastField>
                      </Stack>

                      <Stack flex="1 0 400px">
                        <Stack direction="row">
                          <FastField name={`${fieldPath}[${i}].isSubMenu`}>
                            {(props: FieldProps) => (
                              <FormControlLabel
                                label="子菜單"
                                control={
                                  <Checkbox
                                    name={props.field.name}
                                    checked={props.field.value}
                                    onChange={(e) => {
                                      props.form.setFieldValue(
                                        props.field.name,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                }
                              />
                            )}
                          </FastField>
                          {m.isSubMenu && (
                            <FastField name={`${fieldPath}[${i}].parentLink`}>
                              {(props: FieldProps) => (
                                <TextField
                                  size="small"
                                  autoComplete="off"
                                  label="上級ID"
                                  variant="outlined"
                                  {...props.field}
                                />
                              )}
                            </FastField>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack>
                      <FastField name={`${fieldPath}[${i}].isShownInFooter`}>
                        {(props: FieldProps) => (
                          <FormControlLabel
                            label="同時顯示於頁腳"
                            control={
                              <Checkbox
                                name={props.field.name}
                                checked={props.field.value}
                                onChange={(e) => {
                                  props.form.setFieldValue(
                                    props.field.name,
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                          />
                        )}
                      </FastField>
                    </Stack>
                  </Stack>

                  {/* array controls buttons */}
                  <Stack
                    flex="0 0 80px"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* remove */}
                    {/* <Fab
                      color="default"
                      size="small"
                      sx={{ fontSize: '20px' }}
                      onClick={() => removeItem(i)}
                    >
                      &#10006;
                    </Fab> */}
                    {/* move up */}
                    {i !== 0 && (
                      <Fab
                        color="primary"
                        size="small"
                        sx={{ fontSize: '15px' }}
                        onClick={() => moveUpItem(i)}
                      >
                        &#9650;
                      </Fab>
                    )}

                    {/* move down */}
                    {i < arrayFieldValue.length - 1 && (
                      <Fab
                        color="primary"
                        size="small"
                        sx={{ fontSize: '15px' }}
                        onClick={() => moveDownItem(i)}
                      >
                        &#9660;
                      </Fab>
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          {/* <Stack>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              sx={{ marginTop: '10px', fontSize: '20px' }}
              onClick={() => addItem(getSampleMenuItem())}
            >
              +
            </Fab>
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

export default Menu;
