import SnackContext from './SnackContext';
import { useState } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { ISnackProviderProps, ISnackProps } from './types';

const SnackProvider = (props: ISnackProviderProps) => {
  const [snackProps, setSnackProps] = useState<ISnackProps>({} as ISnackProps);

  const showSnackBar = (content: string, color: AlertColor) => {
    setSnackProps({
      open: true,
      severity: color,
      content,
    });
  };

  const handleClose = () => {
    setSnackProps({
      ...snackProps,
      open: false,
    });
  };

  return (
    <SnackContext.Provider value={{ showSnackBar }}>
      {props.children}
      <Snackbar
        open={snackProps.open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackProps.severity}
          sx={{ width: '100%' }}
        >
          {snackProps.content}
        </Alert>
      </Snackbar>
    </SnackContext.Provider>
  );
};

export default SnackProvider;
