import { CmsUser } from 'common/types';
import { request } from 'common/utils';

export const uploadFile = async (
  bucket: string,
  files: FileList
): Promise<string> => {
  if (files.length === 0) {
    console.error('No file seletced');
    throw new Error('No file seletced');
  }

  const response: Response = await request(
    `/v2/images/${bucket}`,
    undefined,
    files
  );

  if (response) {
    return `/images/${bucket}/${files[0].name}`;
  }
  throw Error(response);
};

export const updatePage = async (
  page: string | null,
  content: any
): Promise<any> => {
  const data: any = page
    ? {
        _id: page,
        ...content,
      }
    : content;

  return await request('/v2/api/update/pages', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const getPage = async (page: string): Promise<any> => {
  const urlPage = `/v2/api/one/pages/${page}`;
  return request(urlPage);
};

export const register = async (
  username: string,
  password: string
): Promise<CmsUser | null> => {
  return await request('/register', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
  });
};

export const login = async (
  username: string,
  password: string
): Promise<CmsUser | null> => {
  return request('/login', {
    body: JSON.stringify({ username, password }),
    method: 'POST',
  });
};

export const logout = () => {
  return request('/logout', {
    method: 'POST',
  });
};

export const reset = async (oldPassword: string, newPassword: string) => {
  const res = await request('/reset', {
    method: 'POST',
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  });
  return res;
};

export const pingAuth = async (): Promise<CmsUser | null> => {
  return await request('/pingAuth', { method: 'POST' });
};
