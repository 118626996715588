import { Stack, TextField, Modal, Button } from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import useValidation from 'hooks/useValidation';
import useAuth from 'hooks/useAuth';
interface IFormResetPasswordProps {
  open: boolean;
  handleClose: () => void;
}

const FormResetPassword = (props: IFormResetPasswordProps) => {
  const { open, handleClose } = props;
  const { validatePassWordForm } = useValidation();
  const { isSubmiting, authReset } = useAuth();

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Formik
          initialValues={{ oldPass: '', newPass: '', newPass2: '' }}
          validate={validatePassWordForm}
          onSubmit={(values, { setSubmitting }) => {
            authReset(values.oldPass, values.newPass);
            setSubmitting(false);
          }}
        >
          <Form>
            <Stack
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
              spacing={3}
            >
              <h1>更改密碼</h1>
              <Field name="oldPass">
                {(props: FieldProps) => (
                  <TextField
                    label="舊密碼"
                    type="password"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    {...props.field}
                    error={props.meta.touched && props.meta.error !== undefined}
                    helperText={props.meta.touched && props.meta.error}
                  />
                )}
              </Field>
              <Field name="newPass">
                {(props: FieldProps) => (
                  <TextField
                    label="新密碼"
                    type="password"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    {...props.field}
                    error={props.meta.touched && props.meta.error !== undefined}
                    helperText={props.meta.touched && props.meta.error}
                  />
                )}
              </Field>
              <Field name="newPass2">
                {(props: FieldProps) => (
                  <TextField
                    label="確認新密碼"
                    type="password"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    {...props.field}
                    error={props.meta.touched && props.meta.error !== undefined}
                    helperText={props.meta.touched && props.meta.error}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        props.form.submitForm();
                      }
                    }}
                  />
                )}
              </Field>
              <Stack direction="row" justifyContent="center" spacing={5}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmiting}
                >
                  確認
                </Button>
                <Button type="button" variant="contained" onClick={handleClose}>
                  取消
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Formik>
      </>
    </Modal>
  );
};

export default FormResetPassword;
