import InputMultLang from 'components/smart/InputMultLang';
import InputFile from 'components/smart/InputFile';
import InputFiles from 'components/smart/InputFiles';
import InputTxtLink from 'components/smart/InputTxtLink';
import Menu from 'pages/General/Menu';
import PageSection from 'components/smart/PageSection';
import { Stack } from '@mui/material';

const SectionHeader = () => {
  return (
    <PageSection sectionTitle="Header">
      <Stack p="10px" spacing={3}>
        <InputMultLang label="電話" fieldPath="telephone" />
        <InputTxtLink label="Whatsapp" fieldPath="whatsapp" />
        <InputMultLang label="電郵" fieldPath="email" />
        <InputMultLang label="地址" fieldPath="address" />
        <InputMultLang label="文字大小" fieldPath="textSize" />
        <InputMultLang label="全局搜尋文字" fieldPath="globalSearch" />
        <InputFile label="權記LOGO" bucket="static" fieldPath="logo" />
        <InputFiles
          bucket="static"
          hasDesc
          hasLink
          label="工具列 - 社交媒體"
          fieldPath="toolbar.media"
        />
        <InputFiles
          bucket="static"
          hasDesc
          hasLink
          label="工具列 - 語言"
          fieldPath="toolbar.translation"
        />
        <Menu label="標題菜單" fieldPath="menu" />
      </Stack>
    </PageSection>
  );
};

export default SectionHeader;
